<section class="content">
    <div class="content-block">
        <div class="breadcrumb-main">
            <div class="row">
                <div class="col-6">
                    <div class="breadcrumb-title">
                        <h4 class="page-title">Reset Password</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center">
            <mat-card class="example-card">
                <mat-card-content>
                    <form class="example-form top-50" [formGroup]="userForm">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Current Password</mat-label>
                            <input type="password" matInput formControlName="oldPassword">
                        </mat-form-field>
                        <div>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>New Password</mat-label>
                                <input type="password" matInput formControlName="newPassword">
                            </mat-form-field>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Confirm Password</mat-label>
                                <input type="password" matInput formControlName="confirmPassword" (ngModelChange)="usernameOnChange($event)">
                                <mat-hint *ngIf="passwordMatch">Password not matching</mat-hint>
                            </mat-form-field>
                            <div class="container-auth-form-btn">
                                <div style="text-align:center">
                                    <button mat-raised-button color="primary" class="auth-form-btn"
                                        (click)="checkPasswordMatch()" [disabled]="btnEnable==false">Change Password</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</section>