import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { ShortINRCurrencyPipe } from "./pipes/short-number.pipe";
import { MinutesSecConversionPipe } from "./pipes/minutes-sec-conversion.pipe";
import { ApplicationPipesModule } from "./pipes/application-pipes.module";
import { MakePositivePipe } from "./pipes/make-positive.pipe";
import { AbsPipe } from "./pipes/abs.pipe";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    FeatherIconsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    ApplicationPipesModule
  ],
  providers: [ShortINRCurrencyPipe, MakePositivePipe, AbsPipe]
})
export class SharedModule {}
