import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceTransformPipe } from './space-transform.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { ParameterTypeTransformPipe } from './parameter-type-transform.pipe';
import { IndianCurrency } from './Indian-currency.pipe';
import { ShortINRCurrencyPipe } from './short-number.pipe';
import { MinutesSecConversionPipe } from './minutes-sec-conversion.pipe';
import { MakePositivePipe } from './make-positive.pipe';
import { AbsPipe } from './abs.pipe';



@NgModule({
  declarations: [
    TitleCasePipe,
    SpaceTransformPipe,
    ParameterTypeTransformPipe,
    ShortINRCurrencyPipe,
    IndianCurrency,
    MinutesSecConversionPipe,
    MakePositivePipe,
    AbsPipe
  ],
  imports: [
    CommonModule, 
  ],
  exports: [
    ParameterTypeTransformPipe,
    IndianCurrency,
    ShortINRCurrencyPipe,
    MakePositivePipe,
    AbsPipe,
    MinutesSecConversionPipe
  ]
})
export class ApplicationPipesModule { 

}
