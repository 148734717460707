import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserManagementService } from './../../../admin/user-management/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  passwordMatch: boolean = false;
  btnEnable: boolean;
  userForm: FormGroup;
  username: string;

  constructor(
    private userService: UserManagementService,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.userForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
  });
}

  ngOnInit(): void {
    this.username = this.router.snapshot.paramMap.get("username");
    this.btnEnable = false;
  }

  checkPasswordMatch() {
    if (this.userForm.valid) {
      if (this.userForm.get("newPassword").value === this.userForm.get("confirmPassword").value) {
        console.log("Password matched");
        let userObj = JSON.stringify({
          "username": this.username,
          "password": this.userForm.get('oldPassword').value,
        });
        this.userService.checkPassword(userObj).subscribe({
          next: (response) => {
            if (response) {
              this.changePassword(userObj);
            }
            else {
              this.openSnackBar("Password old password not matching! Please contact Admin.");
            }
          },
          error: (error) => {
            console.log(error);
          } 
        })
      }
      else {
        console.log("Password not matching.");
      }
    }
  }

  usernameOnChange(confirmPassword: string) {
    if (this.userForm.get('newPassword').value === confirmPassword) {
      this.passwordMatch = false;
      this.btnEnable = true;
    }
    else {
      this.passwordMatch = true;
    }
  }

  changePassword(userObj: any) {
    this.userService.changePassword(userObj).subscribe({
      next: (resp) => {
        if(resp) {
          this.openSnackBar("Password reset successful.");
        }
        else {
          this.openSnackBar("There was a problem resetting password, please try again later.");
        }
      },
      error: () => {
        this.openSnackBar("There was a problem resetting password, please try again later.");
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message);
    setTimeout(()=>{
      this.snackBar.dismiss();
    },3000)
  }
}
