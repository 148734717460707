import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import { ConfigureService } from '../build-config/configure.service';

@Injectable({
    providedIn: 'root'
})
export class SseSubscriptionService {

constructor(private httpClient: HttpClient,
    private configureService: ConfigureService) { }

subscribe(): Subject<any> {
    
    let eventSource = new EventSource(`${this.configureService.config.API_URL}/sse/register`);
    let subscription = new Subject();
    eventSource.addEventListener("message", event=> {
        console.info("Got event: " + event);
        subscription.next(event);
    });
    return subscription;
}

}
