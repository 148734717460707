import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesSecConversion'
})
export class MinutesSecConversionPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    let seconds = +value;
    return Math.floor(seconds / 60) + "Min " + Math.ceil(seconds % 60) + "Sec";
  }

}
