import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariableService {
  baseUrl:string = "api/"
  constructor(private http: HttpClient) { }
  processVariableValue(varValArr:Array<object>):any{ 
    const defaultStructure = {stringValue:"",minValue:"",maxValue:""};
    const defaultValue = null;
    varValArr = [...varValArr];
    varValArr.forEach((varValObj:any,varValObjIndex)=>{
      Object.keys(defaultStructure).map((defaultStructureKey:string,defaultStructureIndex)=>{
        if(varValObj[defaultStructureKey]===undefined){
          varValObj[defaultStructureKey] = defaultValue
        }
      })
    })
    return varValArr;
  }
  processFormData(formData:any){
    formData['value'] = this.processVariableValue(formData['value']);
    formData ={"code":this.generateUniqueStringCode(),...formData}
    return formData
  }
  getAllModels() {
    return this.http.get(this.baseUrl+ "models/simulation-model");
  }
  getModel(id:string) {
    // return this.http.get(this.baseUrl + `models/simulation-model/${id}/parameter`);
    return this.http.get(this.baseUrl + `models/${id}/simulation-model-parameter`);
  }
  getSimulationModelParameterById(id:string){
    return this.http.get(this.baseUrl + `models/simulation-model-parameter/${id}`)
  }
  generateUniqueStringCode():string{
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }
  postVariable(formData:any){
    return this.http.post(this.baseUrl + "variables",this.processFormData(formData))
  }
  deleteVariable(id:number){
    return this.http.delete(this.baseUrl + `variables/${id}`)
  }
  getAllVariable(){
    return this.http.get(this.baseUrl+'variables')
  }
  getVariable(simulationModelId:string,simulationModelParameterId:string){
      return this.http.get(this.baseUrl + `models/${simulationModelId}/simulation-model-group-parameter-value-distinct/${simulationModelParameterId}`)
  }
  getParameterTypeMap(){
    return this.http.get(this.baseUrl + `models/parameter-map`, { observe: 'body', responseType: 'json'})
  }
}
