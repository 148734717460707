import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makePositive'
})
export class MakePositivePipe implements PipeTransform {

  transform(value: number): number {
    if (value >= 100 || value <= -100) {
      return Math.abs(100);
    }
    return Math.abs(value);
  }

}
