import { ConfigureService } from './../../build-config/configure.service';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigureService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.startsWith('api/login')) {
      return next.handle(req);
    } else if (req.url.startsWith('api') || req.url.startsWith('management')) {
      const apiReq = req.clone({url: `${this.configService.config.API_URL}/${req.url}`});
      //this.shiftDates(apiReq.body);
      return next.handle(apiReq);
    } else {
      return next.handle(req);
    }
  }

  shiftDates(body) {
    if (body === null || body === undefined) {
        return body;
    }

    if (typeof body !== 'object') {
        return body;
    }

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof Date) {
            body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
                , value.getSeconds()));
        } else if (typeof value === 'object') {
            this.shiftDates(value);
        }
    }
  }

}
