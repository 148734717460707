import { stringify } from 'querystring';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RoleAuthorities } from './../../core/models/multipleRoleAuthorities';
import { RoleAuthority } from './../../core/models/roleAuthority';
import { Authority } from './../../core/models/authority';
import { Roles } from './../../core/models/roles';
import { User, Users } from './../../core/models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  api_url1 = "api/user/getRoles";
  api_url2 = "api/user/getAuthorities";
  api_url3 = "api/user/getRoleAuthorities";
  api_url4 = "api/user/createUser";
  api_url5 = "api/user/createRole";
  api_url6 = "api/user/createRoleAuthority";
  api_url7 = "api/user/getAuthoritiesByRoleCode";
  api_url8 = "api/user/createMultipleRoleAuthorities";

  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(private http: HttpClient) { }

  getRolesList() {
    return this.http.get<Roles[]>(this.api_url1);
  }

  getAuthoritiesList() {
    return this.http.get<Authority[]>(this.api_url2);
  }

  getRoleAuthoritiesByRoleCode(selectedRole: string) {
    return this.http.get<RoleAuthority[]>(this.api_url7 + "?roleCode=" + selectedRole);
  }

  createUser(data: User) {
    let jsonObj = JSON.stringify(data);
    return this.http.post(this.api_url4, jsonObj, { headers: this.headers, responseType: 'text' });
  }

  createRole(data: Roles) {
    let jsonObj = JSON.stringify(data);
    return this.http.post<Roles>(this.api_url5, jsonObj, { headers: this.headers });
  }

  createRoleAuthority(data: RoleAuthority) {
    let jsonObj = JSON.stringify(data);
    return this.http.post<RoleAuthority>(this.api_url6, jsonObj, { headers: this.headers });
  }

  createMultipleRoleAuthorities(data: RoleAuthorities) {
    let jsonObj = JSON.stringify(data);
    return this.http.post<RoleAuthorities>(this.api_url8, jsonObj, { headers: this.headers });
  }

  checkUsernameExist(username: string) {
    return this.http.get<boolean>("api/user/checkUsername?username=" + username);
  }

  getUsers() {
    return this.http.get<Users[]>("api/user/getUsers");
  }

  getUser(data: string) {
    return this.http.get<Users>("api/user/getUserByUsername?username=" + data);
  }

  updateUser(data: Users) {
    let userObj = JSON.stringify(data);
    return this.http.put<Users>("api/user/updateUser", userObj, {headers: this.headers});
  }

  resetPassword(username: string):Observable<any> {
    return this.http.get("api/user/resetPassword?username=" + username, {responseType: 'text'});
  }

  checkPassword(jsonObj: any) {
    return this.http.post<boolean>("api/user/checkPassword", jsonObj, {headers: this.headers});
  }

  changePassword(jsonObj: any) {
    return this.http.post<boolean>("api/user/changePassword", jsonObj, {headers: this.headers});
  }
}
