import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs';
import { VariableService } from 'src/app/admin/simulation-model/variable.service';


@Pipe({
  name: 'parameterTypeTransform',
  pure:true,
})
export class ParameterTypeTransformPipe implements PipeTransform {
  $variableObservable:any;
  constructor(private http: HttpClient,private variableService: VariableService,private cd: ChangeDetectorRef) {
      // this.variableService.getParameterTypeMap().subscribe({
      //   next:(data:any)=>{
      //     // console.log(value,data)
      //     this.data = data;
      //     return "hye"
      //   }
      // })
   }
  transform(value:any): any {
    // console.log(value)
    // If we cache there will be some value problem 
    // if(this.$variableObservable){
    //   return this.$variableObservable;
    // }
     this.$variableObservable = this.variableService.getParameterTypeMap().pipe(
      map((response:any) => {
          return response[value];
      })
    );
    return this.$variableObservable;
  }

}
