<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img style="width: 40px;height: 40px;position: absolute;top: 10px;left: 20px;"
          src="assets/images/logos-indus/sensei-logo.png" alt="" />
        <img style="width: 283px;height: 250px;position: absolute;top: -55px;left: 50px;"
          [ngClass]="{'fc-daygrid-event-dot':isToggle}"
          src="assets/images/logos-indus/encore-indus-white-background.png" alt="" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <app-feather-icons [icon]="'maximize'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- <li class="nav-item langSelItem" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- #START# Notifications-->
        <li class="nav-item btnNotification" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <app-feather-icons [icon]="'bell'" [class]="'header-icon'"></app-feather-icons>
            <span *ngIf="notifications.length>0" class="label-count" [ngClass]="{'bg-orange':notifications.length>0}"></span>
            <span *ngIf="notifications.length>0" class="button__badge">{{notifications.length}}</span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 350px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="nfc-header" *ngIf="notifications.length==0">
                  <h5 class="mb-0"> <span>No New Notitications</span></h5>
                </li>
                <span *ngIf="notifications.length>0" (click)="clearAll()"><mat-icon class="clearAll">clear_all</mat-icon></span>
                <li>
                  <a href="#" *ngFor="let notification of notifications;let i=index" onClick="return false;"
                    [ngClass]="[notification.status]">
                    <div *ngIf="i<5">
                      <span class="table-img msg-user ">
                        <i class="material-icons-two-tone nfc-type-icon" [ngClass]="['nfc-green']">mail</i>
                      </span>
                      <ng-content *ngIf="notification.eventType=='ECL'; then ecl else eclReport"></ng-content>
                      <ng-template #ecl>
                        <span class="menu-info">
                          <span class="menu-title">ECL data generation for
                            {{notification.simulationModel.name+'-haircut-'+notification.simulationModel.hairCut+'withversionCode-'+notification.simulationModel.versionCode}}{{notification.eventDateTime|date:'short'}}
                          </span>
                          <p [ngStyle]="{color:notification.status=='COMPLETED'?'green':'red'}">{{notification.status}}
                          </p>
                          <span class="menu-desc mt-2">
                            <button class="btn btn-primary dropdown-button-full-width" (click)="goToEcl(notification)"
                              *ngIf="notification.status=='COMPLETED'"> Go To Ecl</button>
                          </span>
                        </span>
                      </ng-template>
                      <ng-template #eclReport>
                        <span class="menu-info">
                          <span class="menu-title">ECL Report</span>
                          <span class="menu-desc mt-2">
                            <button class="btn btn-primary btn-block">Ecl Report</button>
                          </span>
                        </span>
                      </ng-template>
                      <span class="nfc-close" title="mark As Read">
                        <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'" (click)="onClear(i)">
                        </app-feather-icons>
                      </span>
                      <!-- <span class="menu-info">
                      <span class="menu-title">ECL data generated successfully</span>
                      <span class="menu-desc mt-2">
                        <button class="btn btn-primary btn-block">click here to view the same</button>
                      </span>
                    </span>
                    <span class="nfc-close">
                      <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                    </span> -->
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <a class="nfc-read-more">Read
              All Notifications</a> -->
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile btnUserDropdown" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons mt-1">
            <!-- <img src="assets/images/user/admin.jpg" class="user_img" width="32" height="32" alt="User"> -->
            <img src="{{userImg}}" class="user_img" alt="User">
            <!-- <span>{{userName}}</span> -->
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        Hi {{firstName}}
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                      </a>
                    </li> -->
                    <li>
                      <a (click)="resetPassword(userName)">
                        <app-feather-icons [icon]="'key'" [class]="'user-menu-icons'"></app-feather-icons>Reset Passeword
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                      </a>
                    </li> -->
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>