import { LoginResponse } from './../models/token';
import { Role } from './../models/role';
import { User } from './../models/user';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ConfigureService } from "src/app/build-config/configure.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private configureService: ConfigureService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    const body = JSON.stringify({
      "username": username,
      "password": password,
    });
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    });
    return this.http
          .post<any>(`${this.configureService.config.AUTH_API_URL}`, body, {headers})
          .pipe(
            map((response) => {
              localStorage.setItem('token', response.token);
              return response;
            })
    );
  }

  getAccount() {
    return this.http
          .post<any>(`${this.configureService.config.AUTH_ACCOUNT_API_URL}`,{})
          .pipe(
            map((user) => {
              let inUser : User  = {'id': user.id, 'version': 0, 'employeeId': 1111,'img': user.imageUrl, 'username': user.username, 'password': '', 'firstName': user.firstName, 'role': user.role, 'lastName':user.lastName, 'roleAuthorities': user.roleAuthorities};
              localStorage.setItem("currentUser", JSON.stringify(inUser));
              this.currentUserSubject.next(inUser);
              return user;
            })
          );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
